import { InvitationsActionTypes } from './constants';

type InvitationAction = { type: string; payload: {} | string };

// common success
export const invitationApiResponseSuccess = (actionType: string, data: any): InvitationAction => ({
    type: InvitationsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const invitationApiResponseError = (actionType: string, error: string): InvitationAction => ({
    type: InvitationsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getInvitations = (): InvitationAction => ({
    type: InvitationsActionTypes.GET_INVITATIONS,
    payload: {},
});

export const getInvitationById = (id: string): InvitationAction => ({
    type: InvitationsActionTypes.GET_INVITATION_BY_ID,
    payload: { id },
});

export const getInvitationsByTransaction = (id: string): InvitationAction => ({
    type: InvitationsActionTypes.GET_INVITATIONS_BY_TRANSACTION,
    payload: { id },
});

export const getInvitationsByOffer = (id: string): InvitationAction => ({
    type: InvitationsActionTypes.GET_INVITATIONS_BY_OFFER,
    payload: { id },
});

export const getInvitationsByListing = (id: string): InvitationAction => ({
    type: InvitationsActionTypes.GET_INVITATIONS_BY_LISTING,
    payload: { id },
});

export const createInvitation = (data: object): InvitationAction => ({
    type: InvitationsActionTypes.CREATE_INVITATION,
    payload: { data },
});

export const updateInvitation = (id: string, data: object): InvitationAction => ({
    type: InvitationsActionTypes.UPDATE_INVITATION,
    payload: { id, data },
});

export const deleteInvitation = (id: string): InvitationAction => ({
    type: InvitationsActionTypes.DELETE_INVITATION,
    payload: { id },
});

export const sendInvitationByOffer = (id: string, data: object): InvitationAction => ({
    type: InvitationsActionTypes.SEND_INVITATION_BY_OFFER,
    payload: { id, data },
});

export const sendInvitationByListing = (id: string, data: object): InvitationAction => ({
    type: InvitationsActionTypes.SEND_INVITATION_BY_LISTING,
    payload: { id, data },
});

export const sendInvitationByTransaction = (id: string, data: object): InvitationAction => ({
    type: InvitationsActionTypes.SEND_INVITATION_BY_TRANSACTION,
    payload: { id, data },
});

export const revokeInvitationByOffer = (id: string, data: object): InvitationAction => ({
    type: InvitationsActionTypes.REVOKE_INVITATION_BY_OFFER,
    payload: { id, data },
});

export const revokeInvitationByListing = (id: string, data: object): InvitationAction => ({
    type: InvitationsActionTypes.REVOKE_INVITATION_BY_LISTING,
    payload: { id, data },
});

export const cancelInvitation = (id: string, notification: object): InvitationAction => ({
    type: InvitationsActionTypes.CANCEL_INVITATION,
    payload: { id },
});

export const acceptInvitation = (id: string, notification: object): InvitationAction => ({
    type: InvitationsActionTypes.ACCEPT_INVITATION,
    payload: { id },
});

export const resendInvitation = (id: string, data: object): InvitationAction => ({
    type: InvitationsActionTypes.RESEND_INVITATION,
    payload: { id, data },
});

export const resetInvitations = (): InvitationAction => ({
    type: InvitationsActionTypes.RESET_INVITATIONS,
    payload: {},
});

export const resetInvitation = (): InvitationAction => ({
    type: InvitationsActionTypes.RESET_INVITATION,
    payload: {},
});
