import { OrganizationsActionTypes } from './constants';

type OrganizationsAction = { type: string; payload: {} | string };

// common success
export const organizationApiResponseSuccess = (actionType: string, data: any): OrganizationsAction => ({
    type: OrganizationsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const organizationApiResponseError = (actionType: string, error: string): OrganizationsAction => ({
    type: OrganizationsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getOrganizations = (): OrganizationsAction => ({
    type: OrganizationsActionTypes.GET_ORGANIZATIONS,
    payload: {},
});

export const getOrganizationBySlug = (slug: string): OrganizationsAction => ({
    type: OrganizationsActionTypes.GET_ORGANIZATION_BY_SLUG,
    payload: { slug },
});

export const getOrganizationsByUser = (user: string): OrganizationsAction => ({
    type: OrganizationsActionTypes.GET_ORGANIZATIONS_BY_USER,
    payload: { user },
});

export const createOrganization = (data: object): OrganizationsAction => ({
    type: OrganizationsActionTypes.CREATE_ORGANIZATION,
    payload: { data },
});

export const updateOrganization = (slug: string, data: object): OrganizationsAction => ({
    type: OrganizationsActionTypes.UPDATE_ORGANIZATION,
    payload: { slug, data },
});

export const updateOrganizationSettings = (slug: string, data: object): OrganizationsAction => ({
    type: OrganizationsActionTypes.UPDATE_ORGANIZATION_SETTINGS,
    payload: { slug, data },
});

export const getOrganizationSettings = (slug: string): OrganizationsAction => ({
    type: OrganizationsActionTypes.GET_ORGANIZATION_SETTINGS,
    payload: { slug },
});

export const deleteOrganization = (slug: string): OrganizationsAction => ({
    type: OrganizationsActionTypes.DELETE_ORGANIZATION,
    payload: { slug },
});

export const getOrganizationMembersBySlug = (slug: string): OrganizationsAction => ({
    type: OrganizationsActionTypes.GET_ORGANIZATION_MEMBERS_BY_SLUG,
    payload: { slug },
});

export const createOrganizationMember = (slug: string, data: object): OrganizationsAction => ({
    type: OrganizationsActionTypes.CREATE_ORGANIZATION_MEMBER,
    payload: { slug, data },
});

export const updateOrganizationMember = (slug: string, member: string, data: object): OrganizationsAction => ({
    type: OrganizationsActionTypes.UPDATE_ORGANIZATION_MEMBER,
    payload: { slug, member, data },
});

export const removeOrganizationMember = (slug: string, member: string): OrganizationsAction => ({
    type: OrganizationsActionTypes.REMOVE_ORGANIZATION_MEMBER,
    payload: { slug, member },
});

export const resetOrganizations = (): OrganizationsAction => ({
    type: OrganizationsActionTypes.RESET_ORGANIZATIONS,
    payload: {},
});

export const resetOrganization = (): OrganizationsAction => ({
    type: OrganizationsActionTypes.RESET_ORGANIZATION,
    payload: {},
});

export const resetOrganizationSettings = (): OrganizationsAction => ({
    type: OrganizationsActionTypes.RESET_ORGANIZATION_SETTINGS,
    payload: {},
});
