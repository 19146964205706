// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new Invitation
 * @param {*} params
 * @returns
 */
function createInvitation(params: any): any {
    const { data } = params;
    const baseUrl = `${config.server.api}/v1/invitations`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Update the details of a Invitation
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateInvitation(params: any): any {
    const baseUrl = `${config.server.api}/v1/invitations/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * Get a single invitation
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getInvitation(params: any): any {
    const baseUrl = `${config.server.api}/v1/invitations/${params.id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get invitations
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getInvitations(): any {
    const baseUrl = `${config.server.api}/v1/invitations`;
    return api.get(`${baseUrl}`);
}

/**
 * Get invitations for a transaction
 * @param {*} params
 * @returns
 */
function getInvitationsByTransaction(params: any): any {
    const baseUrl = `${config.server.api}/v1/transactions/${params.id}/invitations`;
    return api.get(`${baseUrl}`);
}

/**
 * Get invitations for a transaction
 * @param {*} params
 * @returns
 */
function getInvitationsByListing(params: any): any {
    const baseUrl = `${config.server.api}/v1/listings/${params.id}/invitations`;
    return api.get(`${baseUrl}`);
}

/**
 * Get invitations for a transaction
 * @param {*} params
 * @returns
 */
function getInvitationsByOffer(params: any): any {
    const baseUrl = `${config.server.api}/v1/offers/${params.id}/invitations`;
    return api.get(`${baseUrl}`);
}

/**
 * Send an invitation by offer.
 * @param {*} params
 * @returns
 */
function sendInvitationByOffer(params: any): any {
    const { data, id } = params;
    const baseUrl = `${config.server.api}/v1/offers/${id}/invite`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Send an invitation by listing.
 * @param {*} params
 * @returns
 */
function sendInvitationByListing(params: any): any {
    const { data, id } = params;
    const baseUrl = `${config.server.api}/v1/listings/${id}/invite`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Send an invitation by transaction.
 * @param {*} params
 * @returns
 */
function sendInvitationByTransaction(params: any): any {
    const { data, id } = params;
    const baseUrl = `${config.server.api}/v1/transactions/${id}/invite`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Revoke an invitation by offer.
 * @param {*} params
 * @returns
 */
function revokeInvitationByOffer(params: any): any {
    const { data, id } = params;
    const baseUrl = `${config.server.api}/v1/offers/${id}/invitations/revoke`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Revoke an invitation by listing.
 * @param {*} params
 * @returns
 */
function revokeInvitationByListing(params: any): any {
    const { data, id } = params;
    const baseUrl = `${config.server.api}/v1/listings/${id}/invitations/revoke`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Cancel an invitation.
 * @param {*} params
 * @returns
 */
function cancelInvitation(params: any): any {
    const { id } = params;
    const baseUrl = `${config.server.api}/v1/invitations/${id}/cancel`;
    return api.create(`${baseUrl}`, {});
}

/**
 * Accept an invitation.
 * @param {*} params
 * @returns
 */
function acceptInvitation(params: any): any {
    const { id } = params;
    const baseUrl = `${config.server.api}/v1/invitations/${id}/accept`;
    return api.create(`${baseUrl}`, {});
}

/**
 * Cancel an invitation.
 * @param {*} params
 * @returns
 */
function resendInvitation(params: any): any {
    const { data, id } = params;
    const baseUrl = `${config.server.api}/v1/invitations/${id}/resend`;
    return api.create(`${baseUrl}`, data);
}

export {
    createInvitation,
    updateInvitation,
    getInvitation,
    getInvitations,
    getInvitationsByTransaction,
    getInvitationsByListing,
    getInvitationsByOffer,
    sendInvitationByOffer,
    sendInvitationByListing,
    sendInvitationByTransaction,
    cancelInvitation,
    resendInvitation,
    acceptInvitation,
    revokeInvitationByOffer,
    revokeInvitationByListing,
};
