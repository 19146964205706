import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';

import './i18n';

import App from './App';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // ReactDOM.render(
    <React.StrictMode>
        <Provider store={configureStore({})}>
            <App />
        </Provider>
    </React.StrictMode>
    // document.getElementById('root')
);

reportWebVitals();
