import { PropertyTypeActionTypes } from './constants';

type PropertyTypeAction = { type: string; payload: {} | string };

// common success
export const propertyTypeApiResponseSuccess = (actionType: string, data: any): PropertyTypeAction => ({
    type: PropertyTypeActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const propertyTypeApiResponseError = (actionType: string, error: string): PropertyTypeAction => ({
    type: PropertyTypeActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getPropertyTypes = (options: object): PropertyTypeAction => ({
    type: PropertyTypeActionTypes.GET_PROPERTY_TYPES,
    payload: options,
});

export const getPropertyType = (id: string): PropertyTypeAction => ({
    type: PropertyTypeActionTypes.GET_PROPERTY_TYPE_BY_ID,
    payload: { id },
});

export const createPropertyType = (data: object): PropertyTypeAction => ({
    type: PropertyTypeActionTypes.CREATE_PROPERTY_TYPE,
    payload: { data },
});

export const updatePropertyType = (id: string, data: object): PropertyTypeAction => ({
    type: PropertyTypeActionTypes.UPDATE_PROPERTY_TYPE,
    payload: { id, data },
});
