import { FavoritesActionTypes } from './constants';

type FavoriteAction = { type: string; payload: {} | string };

// common success
export const favoriteApiResponseSuccess = (actionType: string, data: any): FavoriteAction => ({
    type: FavoritesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const favoriteApiResponseError = (actionType: string, error: string): FavoriteAction => ({
    type: FavoritesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getFavorites = (): FavoriteAction => ({
    type: FavoritesActionTypes.GET_FAVORITES,
    payload: {},
});

export const getFavoriteById = (id: string): FavoriteAction => ({
    type: FavoritesActionTypes.GET_FAVORITE_BY_ID,
    payload: { id },
});

export const createFavorite = (data: object): FavoriteAction => ({
    type: FavoritesActionTypes.CREATE_FAVORITE,
    payload: { data },
});

export const updateFavorite = (id: string, data: object): FavoriteAction => ({
    type: FavoritesActionTypes.UPDATE_FAVORITE,
    payload: { id, data },
});

export const deleteFavorite = (id: string): FavoriteAction => ({
    type: FavoritesActionTypes.DELETE_FAVORITE,
    payload: { id },
});

export const addFavoriteToUser = (id: string, data: object): FavoriteAction => ({
    type: FavoritesActionTypes.ADD_FAVORITE_TO_USER,
    payload: { id, data },
});

export const removeFavoriteToUser = (id: string, data: object): FavoriteAction => ({
    type: FavoritesActionTypes.REMOVE_FAVORITE_TO_USER,
    payload: { id, data },
});

export const resetFavorites = (): FavoriteAction => ({
    type: FavoritesActionTypes.RESET_FAVORITES,
    payload: {},
});

export const resetFavorite = (): FavoriteAction => ({
    type: FavoritesActionTypes.RESET_FAVORITE,
    payload: {},
});
