import { BillingActionTypes } from './constants';

type BillingAction = { type: string; payload: {} | string };

// common success
export const billingApiResponseSuccess = (actionType: string, data: any): BillingAction => ({
    type: BillingActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const billingApiResponseError = (actionType: string, error: string): BillingAction => ({
    type: BillingActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getBilling = (): BillingAction => ({
    type: BillingActionTypes.GET_BILLING,
    payload: {},
});

export const getBillingById = (id: string): BillingAction => ({
    type: BillingActionTypes.GET_BILLING_BY_ID,
    payload: { id },
});

export const getBillingByUser = (user: string): BillingAction => ({
    type: BillingActionTypes.GET_BILLING_BY_USER,
    payload: { user },
});

export const getBillingByListing = (id: string): BillingAction => ({
    type: BillingActionTypes.GET_BILLING_BY_LISTING,
    payload: { id },
});

export const getBillingByOrganization = (slug: string): BillingAction => ({
    type: BillingActionTypes.GET_BILLING_BY_ORGANIZATION,
    payload: { slug },
});

export const createBilling = (id: string, data: object): BillingAction => ({
    type: BillingActionTypes.CREATE_BILLING,
    payload: { id, data },
});

export const updateBilling = (id: string, data: object): BillingAction => ({
    type: BillingActionTypes.UPDATE_BILLING,
    payload: { id, data },
});

export const deleteBilling = (id: string): BillingAction => ({
    type: BillingActionTypes.DELETE_BILLING,
    payload: { id },
});

export const resetBilling = (): BillingAction => ({
    type: BillingActionTypes.RESET_BILLING,
    payload: {},
});
