import { AnvilActionTypes } from './constants';

type AnvilAction = {
    type: string;
    payload: {} | string;
};

// common success
export const anvilApiResponseSuccess2 = (actionType: string, data: any, formId: any): AnvilAction => ({
    type: AnvilActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, formId },
});
// common error
export const anvilApiResponseError2 = (actionType: string, error: string, formId: any): AnvilAction => ({
    type: AnvilActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error, formId },
});

export const signEtchPacket2 = (
    parent: string,
    id: string,
    document: string,
    data: object,
    formId: string
): AnvilAction => ({
    type: AnvilActionTypes.SIGN_ETCH_PACKET2,
    payload: { parent, id, document, data, formId },
});

export const resetAnvil2 = (formId: any): AnvilAction => ({
    type: AnvilActionTypes.RESET_ANVIL2,
    payload: { formId },
});
