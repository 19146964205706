import { AnvilActionTypes } from './constants';

type AnvilAction = {
    type: string;
    payload: {} | string;
};

// common success
export const anvilApiResponseSuccess = (actionType: string, data: any): AnvilAction => ({
    type: AnvilActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const anvilApiResponseError = (actionType: string, error: string): AnvilAction => ({
    type: AnvilActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const reviewPDF = (parent: string, id: string, document: string, data: object): AnvilAction => ({
    type: AnvilActionTypes.REVIEW_PDF,
    payload: { parent, id, document, data },
});

export const signEtchPacket = (parent: string, id: string, document: string, data: object): AnvilAction => ({
    type: AnvilActionTypes.SIGN_ETCH_PACKET,
    payload: { parent, id, document, data },
});

export const resetAnvil = (): AnvilAction => ({
    type: AnvilActionTypes.RESET_ANVIL,
    payload: {},
});
