import { ListingActionTypes } from './constants';

type ListingAction = { type: string; payload: {} | string };

// common success
export const listingApiResponseSuccess = (actionType: string, data: any): ListingAction => ({
    type: ListingActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const listingApiResponseError = (actionType: string, error: string): ListingAction => ({
    type: ListingActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createListing = (data: object): ListingAction => ({
    type: ListingActionTypes.CREATE_LISTING,
    payload: { data },
});

export const getListings = (options: object): ListingAction => ({
    type: ListingActionTypes.GET_LISTINGS,
    payload: options,
});

export const getListing = (id: string): ListingAction => ({
    type: ListingActionTypes.GET_LISTING_BY_ID,
    payload: { id },
});

export const getUserListings = (user: string): ListingAction => ({
    type: ListingActionTypes.GET_LISTINGS_BY_USER,
    payload: { user },
});

export const updateListing = (id: string, data: object): ListingAction => ({
    type: ListingActionTypes.UPDATE_LISTING,
    payload: { id, data },
});

export const getAccessByListing = (id: string): ListingAction => ({
    type: ListingActionTypes.GET_LISTING_ACCESS,
    payload: { id },
});

export const revokeAccessByListing = (id: string, data: object): ListingAction => ({
    type: ListingActionTypes.REVOKE_LISTING_ACCESS,
    payload: { id, data },
});

export const resetListings = (): ListingAction => ({
    type: ListingActionTypes.RESET_LISTINGS,
    payload: {},
});
