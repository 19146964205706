import { TerminationActionTypes } from './constants';

type TerminationAction = {
    type: string;
    payload: {} | string;
};

// common success
export const terminationApiResponseSuccess = (actionType: string, data: any): TerminationAction => ({
    type: TerminationActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const terminationApiResponseError = (actionType: string, error: string): TerminationAction => ({
    type: TerminationActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const submitTermination = (id: string, data: object): TerminationAction => ({
    type: TerminationActionTypes.SUBMIT_TERMINATION,
    payload: { id, data },
});

export const confirmTermination = (id: string, data: object): TerminationAction => ({
    type: TerminationActionTypes.CONFIRM_TERMINATION,
    payload: { id, data },
});

export const retractTermination = (id: string, data: object): TerminationAction => ({
    type: TerminationActionTypes.RETRACT_TERMINATION,
    payload: { id, data },
});

export const getTermination = (id: string): TerminationAction => ({
    type: TerminationActionTypes.GET_TERMINATION,
    payload: { id },
});

export const resetTermination = (): TerminationAction => ({
    type: TerminationActionTypes.RESET_TERMINATION,
    payload: {},
});
