import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal/';
import FullLayout from '../layouts/Full';

// session timeout component
import SessionTimeout from './SessionTimeout';

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';

const AppRoutes = (props) => {
    // const { layout, user } = useSelector((state) => ({
    //     layout: state.Layout,
    //     user: state.Auth.user,
    // }));
    const layout = useSelector((state) => state.Layout);
    const user = useSelector((state) => state.Auth.user);

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case layoutConstants.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    let Layout = getLayout();

    return (
        <BrowserRouter>
            <Routes>
                {publicProtectedFlattenRoutes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={
                            <DefaultLayout {...props} layout={layout}>
                                <route.component roles={route.roles} />
                            </DefaultLayout>
                        }
                    />
                ))}

                {authProtectedFlattenRoutes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={
                            <Layout {...props} layout={layout} user={user}>
                                <route.component roles={route.roles} />
                                <SessionTimeout />
                            </Layout>
                        }
                    />
                ))}
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
