import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { invitationApiResponseSuccess, invitationApiResponseError } from './actions';
import { InvitationsActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createInvitation as createInvitationApi,
    getInvitation as getInvitationApi,
    getInvitations as getInvitationsApi,
    getInvitationsByTransaction as getInvitationsByTransactionApi,
    getInvitationsByListing as getInvitationsByListingApi,
    getInvitationsByOffer as getInvitationsByOfferApi,
    updateInvitation as updateInvitationApi,
    sendInvitationByOffer as sendInvitationByOfferApi,
    sendInvitationByListing as sendInvitationByListingApi,
    sendInvitationByTransaction as sendInvitationByTransactionApi,
    revokeInvitationByOffer as revokeInvitationByOfferApi,
    revokeInvitationByListing as revokeInvitationByListingApi,
    cancelInvitation as cancelInvitationApi,
    acceptInvitation as acceptInvitationApi,
    resendInvitation as resendInvitationApi,
} from '../../helpers';

/**
 * Get Invitations
 * @param {object} action
 * @param {string} action.payload
 */
function* getInvitations() {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getInvitationsApi);
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.GET_INVITATIONS, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.GET_INVITATIONS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Invitations by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getInvitation({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getInvitationApi, { id });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.GET_INVITATION_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.GET_INVITATION_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Invitations by Transaction
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getInvitationsByTransaction({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getInvitationsByTransactionApi, { id });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.GET_INVITATIONS_BY_TRANSACTION, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.GET_INVITATIONS_BY_TRANSACTION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Invitations by Listing
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getInvitationsByListing({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getInvitationsByListingApi, { id });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.GET_INVITATIONS_BY_LISTING, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.GET_INVITATIONS_BY_LISTING, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Invitations by Offer
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getInvitationsByOffer({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getInvitationsByOfferApi, { id });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.GET_INVITATIONS_BY_OFFER, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.GET_INVITATIONS_BY_OFFER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Invitation
 * @param {object} action
 * @param {string} action.payload
 * @param {object} action.payload.data
 */
function* createInvitation({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createInvitationApi, { id, data });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.CREATE_INVITATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.CREATE_INVITATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Invitation
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* updateInvitation({ payload: { id, data } }) {
    try {
        const response = yield call(updateInvitationApi, { id, data });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.UPDATE_INVITATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.UPDATE_INVITATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Send an Invitation by offer
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* sendInvitationByOffer({ payload: { id, data } }) {
    try {
        const response = yield call(sendInvitationByOfferApi, { id, data });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.SEND_INVITATION_BY_OFFER, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.SEND_INVITATION_BY_OFFER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Send an Invitation by listing
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* sendInvitationByListing({ payload: { id, data } }) {
    try {
        const response = yield call(sendInvitationByListingApi, { id, data });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.SEND_INVITATION_BY_LISTING, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.SEND_INVITATION_BY_LISTING, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Send an Invitation by transaction
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* sendInvitationByTransaction({ payload: { id, data } }) {
    try {
        const response = yield call(sendInvitationByTransactionApi, { id, data });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.SEND_INVITATION_BY_TRANSACTION, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.SEND_INVITATION_BY_TRANSACTION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Revoke an Invitation by offer
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* revokeInvitationByOffer({ payload: { id, data } }) {
    try {
        const response = yield call(revokeInvitationByOfferApi, { id, data });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.REVOKE_INVITATION_BY_OFFER, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.REVOKE_INVITATION_BY_OFFER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Revoke an Invitation by listing
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* revokeInvitationByListing({ payload: { id, data } }) {
    try {
        const response = yield call(revokeInvitationByListingApi, { id, data });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.REVOKE_INVITATION_BY_LISTING, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.REVOKE_INVITATION_BY_LISTING, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Cancel an Invitation
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* cancelInvitation({ payload: { id } }) {
    try {
        const response = yield call(cancelInvitationApi, { id });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.CANCEL_INVITATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.CANCEL_INVITATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Accept an Invitation
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* acceptInvitation({ payload: { id } }) {
    try {
        const response = yield call(acceptInvitationApi, { id });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.ACCEPT_INVITATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.ACCEPT_INVITATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Resend an Invitation
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* resendInvitation({ payload: { id, data } }) {
    try {
        const response = yield call(resendInvitationApi, { id, data });
        yield put(invitationApiResponseSuccess(InvitationsActionTypes.RESEND_INVITATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(invitationApiResponseError(InvitationsActionTypes.RESEND_INVITATION, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetInvitations() {
    yield takeEvery(InvitationsActionTypes.GET_INVITATIONS, getInvitations);
}

export function* watchGetInvitation() {
    yield takeEvery(InvitationsActionTypes.GET_INVITATION_BY_ID, getInvitation);
}

export function* watchGetInvitationsByTransaction() {
    yield takeEvery(InvitationsActionTypes.GET_INVITATIONS_BY_TRANSACTION, getInvitationsByTransaction);
}

export function* watchGetInvitationsByListing() {
    yield takeEvery(InvitationsActionTypes.GET_INVITATIONS_BY_LISTING, getInvitationsByListing);
}

export function* watchGetInvitationsByOffer() {
    yield takeEvery(InvitationsActionTypes.GET_INVITATIONS_BY_OFFER, getInvitationsByOffer);
}

export function* watchCreateInvitation() {
    yield takeEvery(InvitationsActionTypes.CREATE_INVITATION, createInvitation);
}

export function* watchUpdateInvitation() {
    yield takeEvery(InvitationsActionTypes.UPDATE_INVITATION, updateInvitation);
}

export function* watchSendInvitationByOffer() {
    yield takeEvery(InvitationsActionTypes.SEND_INVITATION_BY_OFFER, sendInvitationByOffer);
}

export function* watchSendInvitationByListing() {
    yield takeEvery(InvitationsActionTypes.SEND_INVITATION_BY_LISTING, sendInvitationByListing);
}

export function* watchSendInvitationByTransaction() {
    yield takeEvery(InvitationsActionTypes.SEND_INVITATION_BY_TRANSACTION, sendInvitationByTransaction);
}

export function* watchRevokeInvitationByOffer() {
    yield takeEvery(InvitationsActionTypes.REVOKE_INVITATION_BY_OFFER, revokeInvitationByOffer);
}

export function* watchRevokeInvitationByListing() {
    yield takeEvery(InvitationsActionTypes.REVOKE_INVITATION_BY_LISTING, revokeInvitationByListing);
}

export function* watchCancelInvitation() {
    yield takeEvery(InvitationsActionTypes.CANCEL_INVITATION, cancelInvitation);
}

export function* watchAcceptInvitation() {
    yield takeEvery(InvitationsActionTypes.ACCEPT_INVITATION, acceptInvitation);
}

export function* watchResendInvitation() {
    yield takeEvery(InvitationsActionTypes.RESEND_INVITATION, resendInvitation);
}

function* invitationSaga() {
    yield all([
        fork(watchGetInvitations),
        fork(watchGetInvitation),
        fork(watchGetInvitationsByTransaction),
        fork(watchGetInvitationsByListing),
        fork(watchGetInvitationsByOffer),
        fork(watchUpdateInvitation),
        fork(watchCreateInvitation),
        fork(watchSendInvitationByOffer),
        fork(watchSendInvitationByListing),
        fork(watchSendInvitationByTransaction),
        fork(watchRevokeInvitationByOffer),
        fork(watchRevokeInvitationByListing),
        fork(watchCancelInvitation),
        fork(watchAcceptInvitation),
        fork(watchResendInvitation),
    ]);
}

export default invitationSaga;
