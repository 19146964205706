import { PropertyStyleActionTypes } from './constants';

type PropertyStyleAction = { type: string; payload: {} | string };

// common success
export const propertyStyleApiResponseSuccess = (actionType: string, data: any): PropertyStyleAction => ({
    type: PropertyStyleActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const propertyStyleApiResponseError = (actionType: string, error: string): PropertyStyleAction => ({
    type: PropertyStyleActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getPropertyStyles = (options: object): PropertyStyleAction => ({
    type: PropertyStyleActionTypes.GET_PROPERTY_STYLES,
    payload: options,
});

export const getPropertyStyle = (id: string): PropertyStyleAction => ({
    type: PropertyStyleActionTypes.GET_PROPERTY_STYLE_BY_ID,
    payload: { id },
});

export const createPropertyStyle = (data: object): PropertyStyleAction => ({
    type: PropertyStyleActionTypes.CREATE_PROPERTY_STYLE,
    payload: { data },
});

export const updatePropertyStyle = (id: string, data: object): PropertyStyleAction => ({
    type: PropertyStyleActionTypes.UPDATE_PROPERTY_STYLE,
    payload: { id, data },
});
