// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new jobs
 * @param {*} params
 * @returns
 */
function createJob(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs`;
    return api.create(`${baseUrl}`, params.data);
}

/**
 * Run jobs
 * @param {*} params
 * @returns
 */
function runJob(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/run/${params.id}`;
    return api.get(`${baseUrl}`);
    //return api.updatePatch(`${baseUrl}`, params);
}

/**
 * Create a new jobs
 * @param {*} params
 * @returns
 */
function toggleJobActiveStatue(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/run`;
    return api.updatePatch(`${baseUrl}`, params);
    // return api.create(`${baseUrl}`, params.data);
}

/**
 * Update the details of a jobs
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateJob(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * Get all jobs
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getJobById(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all jobs
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getJobByJobId(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/${params.data.jobId}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get jobs for a user
 * @param {*} params
 * @returns
 */
function deleteJob(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/${params.id}`;
    return api.get(`${baseUrl}`);
}

export { createJob, runJob, toggleJobActiveStatue, updateJob, getJobById, getJobByJobId, deleteJob };
