import { TransactionsActionTypes } from './constants';

type TransactionsAction = { type: string; payload: {} | string };

// common success
export const transactionApiResponseSuccess = (actionType: string, data: any): TransactionsAction => ({
    type: TransactionsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const transactionApiResponseError = (actionType: string, error: string): TransactionsAction => ({
    type: TransactionsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createTransaction = (): TransactionsAction => ({
    type: TransactionsActionTypes.CREATE_TRANSACTION,
    payload: {},
});

export const getTransactions = (): TransactionsAction => ({
    type: TransactionsActionTypes.GET_TRANSACTIONS,
    payload: {},
});

export const getTransactionById = (id: string): TransactionsAction => ({
    type: TransactionsActionTypes.GET_TRANSACTION_BY_ID,
    payload: { id },
});

export const getTransactionsByUser = (user: string): TransactionsAction => ({
    type: TransactionsActionTypes.GET_TRANSACTIONS_BY_USER,
    payload: { user },
});

export const updateTransaction = (id: string, data: object): TransactionsAction => ({
    type: TransactionsActionTypes.UPDATE_TRANSACTION,
    payload: { id, data },
});

export const deleteTransaction = (id: string): TransactionsAction => ({
    type: TransactionsActionTypes.DELETE_TRANSACTION,
    payload: { id },
});

export const archiveTransaction = (id: string): TransactionsAction => ({
    type: TransactionsActionTypes.ARCHIVE_TRANSACTION,
    payload: { id },
});

export const resetTransactions = (): TransactionsAction => ({
    type: TransactionsActionTypes.RESET_TRANSACTIONS,
    payload: {},
});
