import { NotificationsActionTypes } from './constants';

type NotificationsAction = { type: string; payload: {} | string };

// common success
export const notificationApiResponseSuccess = (actionType: string, data: any): NotificationsAction => ({
    type: NotificationsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const notificationApiResponseError = (actionType: string, error: string): NotificationsAction => ({
    type: NotificationsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getNotifications = (): NotificationsAction => ({
    type: NotificationsActionTypes.GET_NOTIFICATIONS,
    payload: {},
});

export const getNotificationById = (id: string): NotificationsAction => ({
    type: NotificationsActionTypes.GET_NOTIFICATION_BY_ID,
    payload: { id },
});

export const getNotificationsByUser = (user: string): NotificationsAction => ({
    type: NotificationsActionTypes.GET_NOTIFICATIONS_BY_USER,
    payload: { user },
});

export const getNotificationsByTransaction = (id: string): NotificationsAction => ({
    type: NotificationsActionTypes.GET_NOTIFICATIONS_BY_TRANSACTION,
    payload: { id },
});

export const createNotification = (id: string, data: object): NotificationsAction => ({
    type: NotificationsActionTypes.CREATE_NOTIFICATION,
    payload: { id, data },
});

export const updateNotification = (id: string, data: object): NotificationsAction => ({
    type: NotificationsActionTypes.UPDATE_NOTIFICATION,
    payload: { id, data },
});

export const dismissNotification = (id: string): NotificationsAction => ({
    type: NotificationsActionTypes.DISMISS_NOTIFICATION,
    payload: { id },
});

export const acknowledgeNotification = (id: string): NotificationsAction => ({
    type: NotificationsActionTypes.ACKNOWLEDGE_NOTIFICATION,
    payload: { id },
});

export const deleteNotification = (id: string): NotificationsAction => ({
    type: NotificationsActionTypes.DELETE_NOTIFICATION,
    payload: { id },
});

export const resetNotifications = (): NotificationsAction => ({
    type: NotificationsActionTypes.RESET_NOTIFICATIONS,
    payload: {},
});

export const resetNotification = (): NotificationsAction => ({
    type: NotificationsActionTypes.RESET_NOTIFICATION,
    payload: {},
});
