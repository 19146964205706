// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new Transaction
 * @param {*} params
 * @returns
 */
function createTransaction(params: any): any {
    const baseUrl = `${config.server.api}/v1/transactions`;
    return api.create(`${baseUrl}`, params);
}

/**
 * Update the details of a Transaction
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateTransaction(params: any): any {
    const baseUrl = `${config.server.api}/v1/transactions/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * Get transactions by listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getTransactions(params: any): any {
    const baseUrl = `${config.server.api}/v1/transactions`;
    return api.get(`${baseUrl}`);
}

/**
 * Get a single transaction
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getTransaction(params: any): any {
    const baseUrl = `${config.server.api}/v1/transactions/${params.id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get transactions for a user
 * @param {*} params
 * @returns
 */
function getUserTransactions(params: any): any {
    const baseUrl = `${config.server.api}/v1/users/${params.user}/transactions`;
    return api.get(`${baseUrl}`);
}

// /**
//  * Upload a file to the API
//  * @param {Array<File>} params
//  * @returns
//  */
// function createFile(params: any): any {
//     const { parent, id, files } = params;
//     const baseUrl = `${config.server.api}/v1/${parent}/${id}/files`;
//     return api.createWithFile(`${baseUrl}`, files);
// }

// export { createTransaction, updateTransaction, getTransaction, getTransactions, getUserTransactions, createFile };
export { createTransaction, updateTransaction, getTransaction, getTransactions, getUserTransactions };
