import { ExtensionActionTypes } from './constants';

type ExtensionAction = {
    type: string;
    payload: {} | string;
};

// common success
export const extensionApiResponseSuccess = (actionType: string, data: any): ExtensionAction => ({
    type: ExtensionActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const extensionApiResponseError = (actionType: string, error: string): ExtensionAction => ({
    type: ExtensionActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const submitExtension = (id: string, data: object): ExtensionAction => ({
    type: ExtensionActionTypes.SUBMIT_EXTENSION,
    payload: { id, data },
});

export const confirmExtension = (id: string, data: object): ExtensionAction => ({
    type: ExtensionActionTypes.CONFIRM_EXTENSION,
    payload: { id, data },
});

export const retractExtension = (id: string, data: object): ExtensionAction => ({
    type: ExtensionActionTypes.RETRACT_EXTENSION,
    payload: { id, data },
});

export const getExtension = (id: string): ExtensionAction => ({
    type: ExtensionActionTypes.GET_EXTENSION,
    payload: { id },
});

export const resetExtension = (): ExtensionAction => ({
    type: ExtensionActionTypes.RESET_EXTENSION,
    payload: {},
});
