import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NewDisclosuresSuccess = () => {
    const navigate = useNavigate();

    const { transaction } = useSelector((state) => ({
        transaction: state.Transactions.data || {},
    }));

    useEffect(() => {
        if (!transaction?.id) return;
        setTimeout(() => {
            navigate(`/transactions/${transaction?.id}`);
        }, 3000);
    }, [navigate, transaction]);

    return (
        <div className="row d-flex justify-content-center">
            <div className="offer-success-card">
                <div style={{ borderRadius: 200, height: 200, width: 200, background: '#F8FAF5', margin: '0 auto' }}>
                    <i className="offer-success-card__checkmark">✓</i>
                </div>
                <h1>Disclosures Submitted</h1>
                <p>Your Seller's Disclosures have been submitted!</p>
            </div>
        </div>
    );
};

export default NewDisclosuresSuccess;
