import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { listingApiResponseSuccess, listingApiResponseError } from './actions';
import { ListingActionTypes } from './constants';
import config from '../../config';
import { toggleLoading } from '../actions';
import {
    createListing as createListingApi,
    getListing as getListingApi,
    getUserListings as getUserListingsApi,
    updateListing as updateListingApi,
    getAccessByListing as getAccessByListingApi,
    revokeAccessByListing as revokeAccessByListingApi,
} from '../../helpers';

// Import the API helpers
// This is needed for a work-around due to 401 errors
import { APICore } from '../../helpers/api/apiCore';

const api = new APICore();

/**
 * Create a new listing
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.listing
 * @param {object} action.payload.data
 */
function* createListing({ payload: { data } }) {
    // yield put(toggleLoading(true));
    try {
        const response = yield call(createListingApi, { data });
        yield put(listingApiResponseSuccess(ListingActionTypes.CREATE_LISTING, response.data));
    } catch (error) {
        console.log(error);
        yield put(listingApiResponseError(ListingActionTypes.CREATE_LISTING, error));
    }
    // yield put(toggleLoading(false)) is reloading the state which makes the call to
    // Listings/reducer without an action, by default will assign createSuccess to null.

    // yield put(toggleLoading(false));
}

/**
 * Get Listings
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.page
 * @param {string} action.payload.limit
 * @param {string} action.payload.sort
 * @param {string} action.payload.order
 * @param {string} action.payload.search
 * @param {string} action.payload.category
 */
function* getListings({ payload = {} }) {
    yield put(toggleLoading(true));
    try {
        payload = {
            page: payload.page || 1,
            limit: 12,
            address_1: payload?.search?.address_1 ?? '',
            zip: payload?.search?.zip ?? '',
        };
        let url = `${config.server.api}/v1/listings?`;
        Object.keys(payload).forEach((k) => {
            if (payload[k] !== '') {
                url += `${k}=${payload[k]}&`;
            }
        });
        const res = yield api.get(url);
        yield put(listingApiResponseSuccess(ListingActionTypes.GET_LISTINGS, res.data));
    } catch (error) {
        console.log(error);
        yield put(listingApiResponseError(ListingActionTypes.GET_LISTINGS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get listing by ID
 * @param {*} param0
 */
function* getListing({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getListingApi, { id });
        yield put(listingApiResponseSuccess(ListingActionTypes.GET_LISTING_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(listingApiResponseError(ListingActionTypes.GET_LISTING_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get listings by user
 * @param {*} param0
 */
function* getUserListings({ payload: { user } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getUserListingsApi, { user });
        yield put(listingApiResponseSuccess(ListingActionTypes.GET_LISTINGS_BY_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(listingApiResponseError(ListingActionTypes.GET_LISTINGS_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a listing
 */
function* updateListing({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(updateListingApi, { id, data });
        yield put(listingApiResponseSuccess(ListingActionTypes.UPDATE_LISTING, response.data));
    } catch (error) {
        console.log(error);
        yield put(listingApiResponseError(ListingActionTypes.UPDATE_LISTING, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get access by listing
 * @param {*} param0
 * @param {*} param0.payload
 * @param {*} param0.payload.id
 * @param {*} param0.payload.data
 */
function* getAccessByListing({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getAccessByListingApi, { id, data });
        yield put(listingApiResponseSuccess(ListingActionTypes.GET_LISTING_ACCESS, response.data));
    } catch (error) {
        console.log(error);
        yield put(listingApiResponseError(ListingActionTypes.GET_LISTING_ACCESS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Revoke access by listing
 * @param {*} param0
 * @param {*} param0.payload
 * @param {*} param0.payload.id
 * @param {*} param0.payload.data
 */
function* revokeAccessByListing({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(revokeAccessByListingApi, { id, data });
        yield put(listingApiResponseSuccess(ListingActionTypes.REVOKE_LISTING_ACCESS, response.data));
    } catch (error) {
        console.log(error);
        yield put(listingApiResponseError(ListingActionTypes.REVOKE_LISTING_ACCESS, error));
    }
    yield put(toggleLoading(false));
}

export function* watchCreateListing() {
    yield takeEvery(ListingActionTypes.CREATE_LISTING, createListing);
}

export function* watchGetListings() {
    yield takeEvery(ListingActionTypes.GET_LISTINGS, getListings);
}

export function* watchGetListing() {
    yield takeEvery(ListingActionTypes.GET_LISTING_BY_ID, getListing);
}

export function* watchGetUserListings() {
    yield takeEvery(ListingActionTypes.GET_LISTINGS_BY_USER, getUserListings);
}

export function* watchUpdateListing() {
    yield takeEvery(ListingActionTypes.UPDATE_LISTING, updateListing);
}

export function* watchGetAccessByListing() {
    yield takeEvery(ListingActionTypes.GET_LISTING_ACCESS, getAccessByListing);
}

export function* watchRevokeAccessByListing() {
    yield takeEvery(ListingActionTypes.REVOKE_LISTING_ACCESS, revokeAccessByListing);
}

function* listingSaga() {
    yield all([
        fork(watchCreateListing),
        fork(watchGetListings),
        fork(watchGetListing),
        fork(watchGetUserListings),
        fork(watchUpdateListing),
        fork(watchGetAccessByListing),
        fork(watchRevokeAccessByListing),
    ]);
}

export default listingSaga;
