import { RepairActionTypes } from './constants';

type RepairAction = {
    type: string;
    payload: {} | string;
};

// common success
export const repairApiResponseSuccess = (actionType: string, data: any): RepairAction => ({
    type: RepairActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const repairApiResponseError = (actionType: string, error: string): RepairAction => ({
    type: RepairActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const submitRepair = (id: string, data: object): RepairAction => ({
    type: RepairActionTypes.SUBMIT_REPAIR,
    payload: { id, data },
});

export const confirmRepair = (id: string, data: object): RepairAction => ({
    type: RepairActionTypes.CONFIRM_REPAIR,
    payload: { id, data },
});

export const submitCounterRepair = (id: string, data: object): RepairAction => ({
    type: RepairActionTypes.SUBMIT_COUNTER_REPAIR,
    payload: { id, data },
});

export const confirmCounterRepair = (id: string, data: object): RepairAction => ({
    type: RepairActionTypes.CONFIRM_COUNTER_REPAIR,
    payload: { id, data },
});

export const retractRepair = (id: string, data: object): RepairAction => ({
    type: RepairActionTypes.RETRACT_REPAIR,
    payload: { id, data },
});

export const getRepair = (id: string): RepairAction => ({
    type: RepairActionTypes.GET_REPAIR,
    payload: { id },
});

export const resetRepairs = (): RepairAction => ({
    type: RepairActionTypes.RESET_REPAIRS,
    payload: {},
});
