import { ModalActionTypes } from './constants';

type ModalAction = { type: string; payload: {} | string };

export const setActiveModal = (id: string): ModalAction => ({
    type: ModalActionTypes.ACTIVE_MODAL,
    payload: { id },
});

export const resetModal = (): ModalAction => ({
    type: ModalActionTypes.RESET_MODAL,
    payload: {},
});
